<template>
  <v-dialog v-model="dialog" persistent width="800px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-blur
            v-on="{ ...tooltip, ...dialog }"
            class="msaBlue white--text"
          >
            <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        Upload
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Upload {{ isMobile ? '' : 'Training Record Documents' }}
      </v-card-title>
      <v-card-text class="mt-4 px-4">
        <v-row>
          <v-col cols="12" v-if="documents.length > 0">
            <TrainingRecordGroupDetail
              forUploader
              @saved="$emit('groupAdded', $event)"
            />
          </v-col>
          <v-col v-if="documents.length > 0">
            <v-card class="mb-4">
              <v-card-title class="msaBlue white--text text-body-1 py-2 mb-4">
                GLOBAL SETTINGS
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <CustomizedAutoComplete
                      :items="groups"
                      :value="globalTrainingRecordGroupId"
                      @change="
                        globalTrainingRecordGroupId = $event ?? 0;
                        onGlobalGroupSelected();
                      "
                      clearable
                      hide-details
                      label="Select a global folder"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="globalCompletedAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="globalCompletedAt"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="
                            globalCompletedAt = '';
                            onGlobalCompleteAtChanged();
                          "
                          append-icon="mdi-calendar"
                          clearable
                          dense
                          hide-details
                          label="Completed Date"
                          outlined
                          readonly
                        />
                      </template>
                      <v-date-picker
                        v-model="globalCompletedAt"
                        @change="onGlobalCompleteAtChanged()"
                        @input="globalCompletedAtMenu = false"
                        no-title
                        scrollable
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="globalExpireAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="globalExpireAt"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="
                            globalExpireAt = '';
                            onGlobalExpireAtChanged();
                          "
                          append-icon="mdi-calendar"
                          clearable
                          dense
                          hide-details
                          label="Re-certification Date"
                          outlined
                          readonly
                        />
                      </template>
                      <v-date-picker
                        v-model="globalExpireAt"
                        @change="onGlobalExpireAtChanged()"
                        @input="globalExpireAtMenu = false"
                        no-title
                        scrollable
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <CustomizedAutoComplete
                      :items="users"
                      :value="globalAttachedEmployees"
                      @change="
                        globalAttachedEmployees = $event;
                        applyGlobalAttachedEmployees();
                      "
                      clearable
                      deletable-chips
                      hide-details
                      item-text="fullname"
                      label="Attach User(s)"
                      multiple
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card
              v-for="(item, index) in documents"
              :key="index"
              class="mb-4"
            >
              <v-card-title class="msaBlue white--text text-body-1 py-2 mb-4">
                <v-row v-if="!item.showEdit" no-gutters>
                  <v-col>
                    {{ item.name }}
                  </v-col>
                  <v-col class="shrink">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="item.showEdit = true"
                          dark
                          icon
                          small
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      Edit Name
                    </v-tooltip>
                  </v-col>
                  <v-col class="shrink">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="removeDocument(item.id)"
                          color="red"
                          small
                          text
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      Remove
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="item.showEdit">
                  <v-col>
                    <v-text-field
                      v-model="item.name"
                      :rules="required"
                      background-color="white"
                      dense
                      hide-details
                      outlined
                      placeholder="Required"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          @click="item.showEdit = false"
                          color="white"
                          icon
                          small
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <CustomizedAutoComplete
                      :error="
                        item.trainingRecordGroupId == 0 ||
                        !item.trainingRecordGroupId
                      "
                      :items="groups"
                      :value="item.trainingRecordGroupId"
                      @change="
                        globalTrainingRecordGroupId = 0;
                        item.trainingRecordGroupId = $event ?? 0;
                      "
                      clearable
                      hide-details
                      label="Select folder"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="item.completedAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.completedAt"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="globalCompletedAt = ''"
                          append-icon="mdi-calendar"
                          clearable
                          dense
                          hide-details
                          label="Completed Date"
                          outlined
                          readonly
                        />
                      </template>
                      <v-date-picker
                        v-model="item.completedAt"
                        @change="globalCompletedAt = ''"
                        @input="item.completedAtMenu = false"
                        no-title
                        scrollable
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="item.expireAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.expireAt"
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="globalExpireAt = ''"
                          append-icon="mdi-calendar"
                          clearable
                          dense
                          hide-details
                          label="Re-certification Date"
                          outlined
                          readonly
                        />
                      </template>
                      <v-date-picker
                        v-model="item.expireAt"
                        @change="globalExpireAt = ''"
                        @input="item.expireAtMenu = false"
                        no-title
                        scrollable
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <CustomizedAutoComplete
                      :items="users"
                      :value="item.attachedEmployees"
                      @change="
                        globalAttachedEmployees = [];
                        item.attachedEmployees = $event;
                      "
                      clearable
                      deletable-chips
                      hide-details
                      item-text="fullname"
                      label="Attach User(s)"
                      multiple
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$refs.uploader.click()" class="msaBlue white--text">
          SELECT FILES
        </v-btn>
        <input
          @change="onFileSelected"
          accept="application/pdf"
          class="d-none"
          multiple
          ref="uploader"
          type="file"
        />
        <v-spacer />
        <v-btn @click="close()" class="red white--text"> CANCEL </v-btn>
        <v-btn
          :disabled="disableConfirm"
          @click="confirm()"
          class="msaBlue white--text"
        >
          CONFIRM
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TrainingRecordGroupDetail from '@/components/TrainingRecordLibrary/TrainingRecordGroupDetail.vue';
export default {
  name: 'TrainingRecordUploadDialog',
  components: {
    TrainingRecordGroupDetail,
  },
  props: {
    groups: {
      type: Array,
    },
    users: {
      type: Array,
    },
  },
  computed: {
    isFileBadData() {
      return this.documents.some(
        (doc) => doc.trainingRecordGroupId == 0 || !doc.name.trim(),
      );
    },
    disableConfirm() {
      if (!this.documents.length || this.isFileBadData) {
        return true;
      }

      return false;
    },
    documentsWithoutRecertificationDateCount() {
      return this.documents.filter((d) => d.expireAt == '').length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      dialog: false,
      documents: [],
      globalAttachedEmployees: [],
      globalCompletedAt: '',
      globalCompletedAtMenu: false,
      globalExpireAt: '',
      globalExpireAtMenu: false,
      globalTrainingRecordGroupId: 0,
      required: [(v) => !!v || 'This field is required'],
      headers: [
        {
          text: 'File Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Folder',
          value: 'trainingRecordGroupId',
          width: '300px',
          class: 'lightGrey',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          width: '210px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Re-certification Date',
          value: 'expireAt',
          width: '210px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '200px',
          class: 'lightGrey',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    submit() {
      const url = 'upload-training-records';
      const params = new FormData();

      params.append('documentsCount', this.documents.length);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', 'Uploading documents...');

      this.documents.forEach((document, index) => {
        params.append('file' + index, document.file);
        params.append('originalFilename' + index, document.originalFilename);
        params.append('name' + index, document.name);
        params.append('attachedEmployees' + index, document.attachedEmployees);
        params.append('mimeType' + index, document.file.type);
        params.append(
          'trainingRecordGroupId' + index,
          document.trainingRecordGroupId,
        );

        if (document.expireAt != null && document.expireAt != '') {
          params.append(
            'expireAt' + index,
            this.$helpers.formatDateTimeToUTC(document.expireAt),
          );
        }

        if (document.completedAt != null && document.completedAt != '') {
          params.append(
            'completedAt' + index,
            this.$helpers.formatDateTimeToUTC(document.completedAt),
          );
        }
      });

      const headers = { 'Content-Type': 'multipart/form-data' };

      this.$axios
        .post(url, params, headers)
        .then(() => {
          this.$emit('saved');
          this.close();
        })
        .catch((error) => error);
    },
    confirm() {
      if (this.documentsWithoutRecertificationDateCount > 0) {
        this.$root.showMessage(
          'Warning',
          `There ${
            this.documentsWithoutRecertificationDateCount > 1 ? 'are' : 'is'
          } ${this.documentsWithoutRecertificationDateCount} training record${
            this.documentsWithoutRecertificationDateCount > 1 ? 's' : ''
          } without a re-certification date. It is recommended to enter a re-certification date so you can be notified prior to expiration.`,
          () => this.submit(),
          () => false,
          'Confirm',
          'Cancel',
        );
      } else {
        this.submit();
      }
    },
    async onFileSelected(event) {
      const invalidFiles = [];
      for (const file of event.target.files) {
        try {
          await this.$helpers.processFile(
            file,
            this.$constants.ACCEPTED_MIME_TYPES.PDF,
            true,
          );
        } catch (error) {
          invalidFiles.push(file.name);
          continue;
        }

        const filenameInfo = this.$helpers.splitFilenameByExtension(file.name);
        const document = {
          id: await this.createGuid(),
          trainingRecordGroupId: this.globalTrainingRecordGroupId,
          originalFilename: file.name,
          name: filenameInfo.name,
          expireAt: this.globalExpireAt,
          completedAt: this.globalCompletedAt,
          file: file,
          attachedEmployees: [],
          completedAtMenu: false,
          expireAtMenu: false,
          isEditable: true,
          assignmentCount: 0,
          showEdit: false,
        };
        this.documents.push(document);
      }

      if (invalidFiles.length > 0) {
        this.$root.showMessage(
          'Invalid file types.',
          `<p>Only PDFs smaller than 100MB are accepted. The following files are not selected:</p>${invalidFiles.join(
            '<br>',
          )}`,
          () => false,
          null,
          'OK',
        );
      }
      this.$refs.uploader.value = null;
    },
    createGuid() {
      const url = 'create-guid?format=json';
      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => response.data);
    },
    removeDocument(documentId) {
      const index = this.documents.findIndex((d) => d.id == documentId);
      this.documents.splice(index, 1);
      if (this.documents.length == 0) {
        this.globalTrainingRecordGroupId = 0;
        this.globalCompletedAt = '';
        this.globalExpireAt = '';
      }
    },
    close() {
      this.dialog = false;
      this.documents = [];
      this.globalAttachedEmployees = [];
      this.globalCompletedAt = '';
      this.globalCompletedAtMenu = false;
      this.globalExpireAt = '';
      this.globalExpireAtMenu = false;
      this.globalTrainingRecordGroupId = 0;
    },
    onGlobalGroupSelected() {
      this.documents.forEach(
        (d) => (d.trainingRecordGroupId = this.globalTrainingRecordGroupId),
      );
    },
    onGlobalCompleteAtChanged() {
      this.documents.forEach((d) => (d.completedAt = this.globalCompletedAt));
    },
    onGlobalExpireAtChanged() {
      this.documents.forEach((d) => (d.expireAt = this.globalExpireAt));
    },
    applyGlobalAttachedEmployees() {
      this.documents.forEach(
        (d) => (d.attachedEmployees = this.globalAttachedEmployees),
      );
    },
  },
};
</script>
