<template>
  <v-dialog v-model="dialog" width="1000px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="selectedItems.length"
        :value="selectedItems.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px
        }`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
          @click="resetData()"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Training Records
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Edit {{ isMobile ? '' : 'Training Records' }} </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row v-if="showEditWarning">
          <v-col>
            <v-alert
              color="#FEF4E4"
              dark
              :style="{ color: '#B98A38' }"
              class="mb-0"
            >
              One or more training records were
              <b>automatically generated from</b> online training courses and
              <b>therefore cannot be edited</b>.
            </v-alert>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="shrink pr-0">
            <v-icon>mdi-folder-outline</v-icon>
          </v-col>
          <v-col class="grow">
            <CustomizedAutoComplete
              :value="globalGroupId"
              hide-details
              :items="groups"
              :label="`Select a folder to move ${
                isMobile ? 'to' : 'the training records to'
              }`"
              @change="
                globalGroupId = $event;
                applyGlobalGroup();
              "
            />
          </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              :value="globalAttachedEmployees"
              multiple
              :key="reload"
              hide-details
              deletable-chips
              :items="employees"
              item-text="fullname"
              label="Select users to attach"
              prepend-icon="mdi-account-multiple"
              @focus="disableConfirm = true"
              @blur="sanitizeAutoComplete()"
              @change="globalAttachedEmployees = $event"
            />
          </v-col>
          <v-col cols="12" v-if="!isMobile">
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Selected Training Records</th>
                      <th>Folder</th>
                      <th class="text-right">
                        <v-btn
                          text
                          color="msaBlue"
                          @click="handlePublishAll()"
                          class="text-capitalize"
                        >
                          {{ publishBtnText }}
                        </v-btn>
                      </th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in edited" :key="item.id">
                      <td>
                        {{ item.name }}
                      </td>
                      <td :style="{ width: '350px' }">
                        <CustomizedAutoComplete
                          :value="item.trainingRecordGroupId"
                          hide-details
                          :items="groups"
                          @change="
                            globalGroupId = null;
                            item.trainingRecordGroupId = $event;
                          "
                        />
                      </td>
                      <td align="center" :style="{ width: '200px' }">
                        <v-row no-gutters align="center">
                          <v-spacer></v-spacer>
                          <v-col class="shrink">
                            <v-switch
                              class="mt-0"
                              v-model="item.isPublished"
                              color="primary"
                              dense
                              hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="5">
                            {{ item.isPublished ? 'Publish' : 'Unpublish' }}
                          </v-col>
                        </v-row>
                      </td>
                      <td :style="{ width: '60px' }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              color="red"
                              @click="unselect(item)"
                              v-on="on"
                            >
                              <v-icon> mdi-minus-circle-outline </v-icon>
                            </v-btn>
                          </template>
                          <span>Remove From List</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col v-if="isMobile" cols="12">
            <v-btn
              class="msaBlue white--text mb-4"
              @click="handlePublishAll()"
              width="100%"
            >
              {{ publishBtnText }}
            </v-btn>
            <v-card v-for="item in edited" :key="item.id" class="mb-4">
              <v-card-title class="text-body-1 msaGrey">
                {{ item.name }}
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row no-gutters align="center">
                  <v-col class="shrink">
                    <v-switch
                      class="mt-0"
                      v-model="item.isPublished"
                      color="primary"
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="3">
                    {{ item.isPublished ? 'Publish' : 'Unpublish' }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="shrink ml-4">
                    <v-btn icon color="red" @click="unselect(item)">
                      <v-icon> mdi-minus-circle-outline </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red white--text" @click="deleteAll()">
          Delete all {{ isMobile ? '' : 'training records' }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="unselectAll" text color="red">cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="confirm()"
          :disabled="disableConfirm"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TrainingRecordSelectionModal',
  props: {
    selectedItems: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      globalGroupId: null,
      globalAttachedEmployees: [],
      employees: [],
      groups: [],
      filters: {
        ...this.$constants.TRAINING_RECORD_LIBRARY_FILTERS,
      },
      disableConfirm: false,
      reload: false,
      edited: [],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    publishBtnText() {
      return this.edited.some((i) => !i.isPublished)
        ? 'Publish All'
        : 'Unpublish All';
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 292 / 2;
    },
    isNonEditableTrainingRecord() {
      return this.edited.some((i) => i.isEditable == 0);
    },
    showEditWarning() {
      return (
        this.isNonEditableTrainingRecord &&
        this.globalAttachedEmployees.length > 0
      );
    },
  },
  methods: {
    getGroups() {
      const params = {
        filters: this.filters,
        loaderText: 'Loading...',
      };
      return this.$axios
        .post('get-training-record-groups-by-company?format=json', params)
        .then((response) => {
          this.groups = response.data.fullGroups;
        })
        .catch((error) => error);
    },
    confirm() {
      const params = {
        loaderText: 'Saving...',
        trainingRecords: this.edited,
        attachedEmployees: this.globalAttachedEmployees,
      };

      const url = 'training-record-mass-management?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('onSave');
          this.close();
        })
        .catch((error) => error);
    },
    handlePublishAll() {
      if (this.edited.some((i) => !i.isPublished)) {
        this.edited.map((i) => (i.isPublished = 1));
      } else {
        this.edited.map((i) => (i.isPublished = 0));
      }
    },
    unselect(item) {
      this.$emit('onUnselect', item);
      this.edited = this.edited.filter((i) => i.id !== item.id);
      if (this.edited.length == 0) {
        this.close();
      }
    },
    unselectAll() {
      this.$emit('unselectAll', {});
      this.close();
    },
    close() {
      this.dialog = false;
    },
    deleteAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure? This is irreversible.',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
      const confirm = () => {
        const itemIds = this.edited.map((i) => i.id);
        const params = {
          loaderText: 'Loading...',
          trainingRecordIds: itemIds,
        };
        const url = 'delete-training-records?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('onSave');
            this.close();
          })
          .catch((error) => error);
      };
    },
    applyGlobalGroup() {
      this.edited.forEach(
        (i) => (i.trainingRecordGroupId = this.globalGroupId),
      );
    },
    getAvailableEmployees() {
      const params = {
        filters: { employeeName: '', labelIds: [], supervisorIds: [] },
        // passes in NaN to get all employees
        trainingRecordId: 'xx',
        loaderText: 'Loading...',
        options: { page: 1, itemsPerPage: 99999 },
      };
      const url = 'get-employees-for-attaching-to-training-record?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.employees = response.data.employees;
        })
        .catch((error) => error);
    },
    onGlobalAttachedEmployeeChanged() {
      this.edited.forEach((i) => {
        if (i.isEditable) {
          i.attachedEmployees = this.globalAttachedEmployees;
        } else {
          i.attachedEmployees = [];
        }
      });
    },
    resetData() {
      this.getGroups();
      this.edited = JSON.parse(JSON.stringify(this.selectedItems));
    },
    sanitizeAutoComplete() {
      this.reload = !this.reload;
      this.disableConfirm = false;
    },
  },
  mounted() {
    this.getAvailableEmployees();
  },
};
</script>

<style>
/* override publish button disabled style */
#publish-btn-toggle .v-btn--disabled.publish-btn {
  background-color: #3564ac !important;
  color: white !important;
}

#publish-btn-toggle .v-btn--disabled.publish-btn .publish-icon {
  color: #4caf50 !important;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
